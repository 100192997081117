<template align-center class="pa-0">

<v-container class="pa-0 mb-4">


    <v-toolbar flat color="opcion1">
        <v-toolbar-title>Analisis de Gestiones Contabilidad
      
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>


         <v-flex  xs4 sm4 md2 lg2  class="mt-2">
              <v-text-field
              class="mt-3"
              prepend-icon="today"
              v-model="fecha_inicio"
              :disabled="true"
            ></v-text-field>
         </v-flex>

        <v-flex  xs4 sm4 md2 lg2  class="mt-2">
              <v-text-field
                class="mt-3"
                prepend-icon="today"
                v-model="fecha_fin"
                :disabled="true"
              ></v-text-field>
              </v-flex>



         <v-btn color="indigo" dark class=" ml-9 mt-1" @click="dialogInicio=true"><v-icon>today</v-icon></v-btn>


        <v-btn color="success" @click="Procesar()" class="mt-1 ml-8"> 
                  <v-icon dark>sync</v-icon>
                </v-btn>


    </v-toolbar>

     <v-row class="mt-1 mx-1">




   <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-card>
                  
                  <v-card-title>Gestiones por ejecutivo</v-card-title>

                  <v-divider></v-divider>

                    <v-card-text>
                    <apexchart width="300"  height="250"  type="pie" :options="optionsPie" :series="seriesPie"></apexchart>  


                    
                     <v-divider class="mt-2"></v-divider>
                    <v-icon  class="mr-2" small>home_repair_service</v-icon>
                    <span>Total Gestiones:
                      <v-chip class="ma-2"  dark color="red"> {{ cantidadTotalGestiones }}</v-chip>
                    </span>
                    </v-card-text>
                </v-card>
        </v-col>















        <v-col cols="12" sm="12" md="7" lg="7" xl="7">
                <v-card>
                  
                  <v-card-title>Gestiones realizadas</v-card-title>

                    <v-card-text>
                    <apexchart width="550"  height="250"  type="bar" :options="optionsGestiones" :series="seriesGestiones"></apexchart>  

                    </v-card-text>
                </v-card>
        </v-col>


          <v-dialog v-model="dialogInicio" persistent max-width="280">
        <v-card>
          <v-toolbar flat color="red" class="text-center">
          <v-toolbar-title class="white--text  text-center"><strong>Analisis por fechas</strong></v-toolbar-title>
        </v-toolbar>

          <v-card-text>



            <v-flex>
        
            

                      <v-menu
                        ref="menuFecha1"
                        v-model="menuFecha1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_inicio"
                                label="Fecha inicio"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_inicio" locale="es"
                            no-title  @input="menuFecha1 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
                </v-flex>

            <v-flex>
                  <v-menu
                    ref="menuFecha2"
                    v-model="menuFecha2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                
                    offset-y
                    min-width="290px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_fin"
                            label="Fecha fin"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="fecha_fin" locale="es"
                        no-title  @input="menuFecha2 = false" scrollable>
                        </v-date-picker>
                  </v-menu>

                </v-flex>

            
                  <v-flex justify-center d-flex>
                    
                        <v-btn color="success" dark @click="Procesar()" style="margin-right:10px;">Generar</v-btn>
                      <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

                </v-flex>
    
          </v-card-text>
        
        </v-card>
        </v-dialog>




        <v-col cols="6" sm="12" md="6" lg="6" xl="6">

                <v-card>
                  
                  <v-card-title>Montos Promesas de pago</v-card-title>

                    <v-card-text>
                    <apexchart width="480"  height="200"  type="bar" :options="optionsPromesas" :series="seriesPromesas"></apexchart>  


                    
                     <v-divider class="mt-2"></v-divider>

                   <v-icon  class="mr-2" small>label</v-icon>
                      <span>Promesas pendientes:
                       <v-chip class="ma-2"  dark color="indigo"> {{ CantidadtotalPromesas }}</v-chip>
                     </span>


                    <v-icon  class="mr-2" small>money</v-icon>
                    <span>Monto Promesas:
                      <v-chip class="ma-2"  dark color="success"> {{ MontoTotalPromesa }}</v-chip>
                    </span>

                     </v-card-text>
                </v-card>
        </v-col>



          <v-col cols="6" sm="12" md="6" lg="6" xl="6">

                <v-card>
                  
                  <v-card-title>Promesas exitosas</v-card-title>

                    <v-card-text>
                    <apexchart width="480"  height="200"  type="bar" :options="optionsPromesasE" :series="seriesPromesasE"></apexchart>  

                     </v-card-text>
                </v-card>
        </v-col>


     </v-row>


     <v-row>
        <v-col cols="12">


            <v-data-table
                :headers="headers"
                :items="arregloTablaPromesas"
                :search="search"
                sort-by="calories"
                class="elevation-1"
            >
       <template v-slot:top>
         <v-toolbar flat color="opcion1">
        <v-toolbar-title>Historial Promesas de pago</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

         <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>

        <v-spacer></v-spacer>






        <v-dialog v-model="dialog" max-width="500px">

          <v-card>
           <v-toolbar color="red">
            <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                
                <v-col cols="12">
                    <v-text-field v-model="editedItem.descripcion" label="Descripcion">
                    </v-text-field>

                </v-col>
               
            <v-col cols="6">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="editedItem.fecha"
                    label="Fecha agendado pago"
                    prepend-icon="date_range"
                 
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="editedItem.fecha"
                    no-title
                    @input="menu2 = false"
                ></v-date-picker>
                </v-menu>
            </v-col>


            <v-col cols="6">
                    <v-select
                        v-model="editedItem.status"
                        :items="arrayStatus"
                        :menu-props="{ top: true, offsetY: true }"
                        prepend-icon="label"
                        label="Estatus"
                        
                    ></v-select>

            </v-col>
                 
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text color="secondary" @click="dialog = false">
                Cancelar
              </v-btn>

              <v-btn color="success" @click="save()">
                Guardar
              </v-btn>
            
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">seguro quieres eliminar este registro</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>


    
    <template v-slot:[`item.fecha`]="{ item }">
        <span>{{ getFecha(item.fecha)}}</span>
        </template>


    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        edit
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="ListarHistorialPromesas()">
        Reset
      </v-btn>
    </template>
  </v-data-table>


        </v-col>
     </v-row>


</v-container>

</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";

import VueApexCharts from 'vue-apexcharts';


export default {
      components:{
     apexchart: VueApexCharts
  },
  data(){
     return{  

                 //opciones del dialogo de fechas
                menu: false,
                dialogInicio:true,
                menuFecha1:false,
                fecha_inicio:new Date().toISOString().substr(0, 10),
                menuFecha2:false,
                fecha_fin:new Date().toISOString().substr(0, 10),
                date: new Date().toISOString().substr(0, 10),

                optionsGestiones:{},
                seriesGestiones:[],

                ArrayEjecutivosCTA:[],

                cantidadTotalGestiones:0,

                CantidadtotalPromesas:0,
                MontoTotalPromesa:0,

                optionsPromesas:{},
                seriesPromesas:[],

                arrayPagosAgendados:[],

                dialog: false,
                dialogDelete: false,

                search:'',
                headers: [
                            { text: 'Cliente', value: 'id_agendapago_cliente.nombre_cliente' },
                            { text: 'fecha', value: 'fecha' },
                            { text: 'descripcion', value: 'descripcion' },
                            { text: 'status', value: 'status' },
                            { text: 'monto', value: 'monto'},
                            { text: 'Ejecutivo', value: 'id_agendapago_ejecutivo.nombre_ejecutivo'},
                            { text: 'Acciones', value: 'actions', sortable: false },
                         ],

                arregloTablaPromesas:[],

                 editedItem: {
                            id_agendapago_cliente:'',
                            fecha:new Date().toISOString().substr(0, 10),
                            descripcion:'',
                            status:'',
                            monto:0,
                            id_agendapago_ejecutivo:''
                },
                defaultItem: {
                             id_agendapago_cliente:'',
                            fecha:new Date().toISOString().substr(0, 10),
                            descripcion:'',
                            status:'',
                            monto:0,
                            id_agendapago_ejecutivo:''
                },

                arrayStatus: ['Agendado', 'Exitoso', 'Cancelado'],

                 menu2:false,

                 seriesPie:[1,1],
                 optionsPie:{
                      chart: {
                              width: 300,
                              type: 'pie',
                            },
                            labels:['A', 'B'],
                            legend: {
                                      position: 'right'
                                      },

                                       dataLabels: {
                                        enabled:true,
                                       }
                        },


                optionsPromesasE:{},
                seriesPromesasE:[]
          





       }
  },

   created () {

    this.listarEjecutivos();


   },
    watch: {

    dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    computed: {

    ...mapState(['usuario', 'token']),

    formTitle () {
        return this.editedIndex === -1 ? 'Nueva promesa' : 'Edicion'
      },

    },

     methods:{

      ...mapMutations(['mostrarLoading','ocultarLoading']),

        Procesar(){


          this.cantidadTotalGestiones=0;

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;

            axios.post('gestiones-departamento',
                               { 
                                'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                                'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10),
                                'departamento':'Contabilidad' 
                              },
             config
          ).then(function(response){
                console.log(response);
                 let respuesta=response.data;

                 me.cantidadTotalGestiones=respuesta.length;

               let arrayLlamada=[];
               let arrayWhatsapp=[];
               let arrayCorreo=[];
               let arrayTelegram=[];
               let arrayVisita=[];

               let arrayLabel=[];
               let arrayTotal=[];

                me.ArrayEjecutivosCTA.map(function(x){

                    let contador_llamada=0;
                    let contador_whatsapp=0;
                    let contador_correo=0;
                    let contador_telegram=0;
                    let contador_visita=0;
                    let total=0;

                        respuesta.map(function(y){

                            if(y.id_gestion_ejecutivo._id==x._id){
                                if(y.tipo_gestion=='Llamada'){contador_llamada++;}
                                if(y.tipo_gestion=='Whatsapp'){contador_whatsapp++;}
                                if(y.tipo_gestion=='Correo'){contador_correo++;}
                                if(y.tipo_gestion=='Telegram'){contador_telegram++;}
                                if(y.tipo_gestion=='Visita'){contador_visita++;}
                                total++;
                            }

                        });

                        arrayLabel.push(x.nombre_ejecutivo);
                        arrayLlamada.push(contador_llamada);
                        arrayWhatsapp.push(contador_whatsapp);
                        arrayCorreo.push(contador_correo);
                        arrayTelegram.push(contador_telegram);
                        arrayVisita.push(contador_visita);

                        arrayTotal.push(total);


                });


                me.seriesGestiones=[
                                        { name: 'Llamadas', data: arrayLlamada },
                                        { name: 'Whastapp', data: arrayWhatsapp },
                                        { name: 'Correos', data: arrayCorreo }, 
                                        { name: 'Telegram', data: arrayTelegram },
                                        { name: 'Visitas', data: arrayVisita }
                                    ];


                me.optionsGestiones= {
                                        chart: {
                                                type: 'bar', height: 250, stacked: true,
                                                toolbar: { show: true },
                                                zoom: { enabled: true }
                                            },
                                       
                                        plotOptions: {
                                        bar: {
                                            horizontal: false,
                                            borderRadius: 10
                                        },
                                        },
                                        xaxis: {
                                        categories: arrayLabel,
                                        },
                                        legend: {
                                        position: 'top'
                                        },
                                        fill: {
                                        opacity: 1
                                        }
                                    };


                            me.seriesPie=arrayTotal;

                            me.optionsPie={
                                  chart: {
                                          width: 300,
                                          type: 'pie',
                                        },
                                        labels:arrayLabel,
                                        legend: {  show: true,
                                                  position: 'right'
                                                  },

                                                  dataLabels: {
                                                    enabled:true,
                                                  }
                                    };
                                        


             me.ocultarLoading();
             me.dialogInicio=false;

             me.ListarHistorialPromesas();

             me.listarPromesasPago();
            

          }).catch(function(error){
            console.log(error);
          });


        },

         listarEjecutivos(){

                let config={headers:{token:this.token}};
                this.mostrarLoading({titulo:'Accediendo a datos'});
                let arregloe=[];
                let me=this;
                axios.get('ejecutivos',
                config
                ).then(function(response){
                    console.log(response);
                    arregloe=response.data;

                    arregloe.map(function(x){
                        if(x.rol=='CONTA'){
                            me.ArrayEjecutivosCTA.push(x);
                        }
                    });

                me.ocultarLoading();
                }).catch(function(error){
                    console.log(error);
                });
        },


        listarPromesasPago(){


            this.CantidadtotalPromesas=0;

                let config={headers:{token:this.token}};
                this.mostrarLoading({titulo:'Accediendo a datos'});

                 let me=this;
                  me.arrayPagosAgendados=[];

                    axios.post('agendapagos-pendiente',
                              { 
                                'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                                'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10),
                              },
                    config
                    ).then(function(response){
                        console.log(response.data);
                          me.arrayPagosAgendados=response.data;

                          me.CantidadtotalPromesas=me.arrayPagosAgendados.length;


                          let arrayLabel=[];
                          let arrayMontos=[];



                           me.ArrayEjecutivosCTA.map(function(x){

                            let monto=0;

                        
                                    me.arrayPagosAgendados.map(function(y){

                                        if(y.id_agendapago_ejecutivo._id==x._id){
                                            monto=monto+parseInt(y.monto);
                                        }

                                    });  

                                    arrayLabel.push(x.nombre_ejecutivo);

                                    console.log('insertamos:'+monto);
                                    arrayMontos.push(monto);

                                    me.MontoTotalPromesa=me.MontoTotalPromesa+monto;
                            
                            });


                        me.seriesPromesas=[ { name: 'Montos Promesa', data: arrayMontos } ];

                        console.log( me.seriesPromesas);


                           me.optionsPromesas={
                            chart: { type: 'bar', height: 350},
                            plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded'
                            }
                            },
                            dataLabels: { enabled: true },
                            stroke: { show: true, width: 2, colors: ['transparent'] },
                            xaxis: { categories: arrayLabel },
                            fill: { opacity: 1 }
                        };


                  
                    

                    me.ocultarLoading();
                    }).catch(function(error){
                        console.log(error);
                    });
        },


       ListarHistorialPromesas(){

                        let config={headers:{token:this.token}};
                            this.mostrarLoading({titulo:'Accediendo a datos'});

                            let me=this;
                            me.arregloTablaPromesas=[];

                                axios.post('agendapagos-Historico',
                                        { 
                                            'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                                            'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10),
                                        },
                                config
                                ).then(function(response){
                                    console.log(response.data);
                                    me.arregloTablaPromesas=response.data;


                                    let arrayLabel=[];
                                    let arrayTotal=[];
                                    me.ArrayEjecutivosCTA.map(function(x){

                                           let total=0;


                                            me.arregloTablaPromesas.map(function(y){

                                              if(y.status=='Exitoso'){
                                                 
                                                  if(y.id_agendapago_ejecutivo._id==x._id){
                                                      total++;
                                                    }
                                              }

                                            });  

                                          arrayLabel.push(x.nombre_ejecutivo);
                                          arrayTotal.push(total);

                                    
                                    
                                    });



                                      me.optionsPromesasE={
                                        chart: {
                                                  type: 'bar',
                                                  height: 350
                                                },
                                                  dataLabels: {
                                                  enabled: true
                                                },

                                                xaxis: {
                                                  categories: arrayLabel
                                                }

                                      };
                                      me.seriesPromesasE=[{ name: 'Exitosas', data: arrayTotal}];


                                    me.ocultarLoading();
                                }).catch(function(error){
                                    console.log(error);
                                });

       },


       editItem (item) {
        this.editedIndex = this.arregloTablaPromesas.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.editedItem.fecha=new Date(this.editedItem.fecha).toISOString().substr(0, 10);
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.arregloTablaPromesas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
       
            let config={headers:{token:this.token}};
            
                    axios.put(`agendapagos/${this.editedItem._id}`,
                              { '_id':this.editedItem._id,'activo':0},
                            config
                        ).then(response=>{
                      
                        console.log(response);
                     

                        this.closeDelete();
                        this.Procesar();

                  }).catch(e=>{
                        console.log(e)
                  }); 

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {

      
          let config={headers:{token:this.token}};
           this.mostrarLoading({titulo:'Accediendo a datos'});

           let me=this;

      
        axios.put(`agendapagos/${this.editedItem._id}`,
                { 
                  'fecha':new Date(this.editedItem.fecha),
                  'descripcion':this.editedItem.descripcion,
                  'status':this.editedItem.status,
                  'monto':parseFloat(this.editedItem.monto)
                  },
                  config

                  ).then(response=>{

                   console.log(response);
                   me.ocultarLoading();
                   me.dialog=false;
                
                   me.Procesar();
        

                }).catch(e=>{
                      console.log(e)
                      this.mensaje=e
                });




          this.close()


        }
        
      },

    getFecha(evaluar) {
        let date = new Date(evaluar),
        year = date.getFullYear(),
        month = (date.getMonth() + 1).toString(),
        formatedMonth = (month.length === 1) ? ("0" + month) : month,
        day = date.getDate().toString(),
        formatedDay = (day.length === 1) ? ("0" + day) : day;
        return formatedDay + "-" + formatedMonth + "-" + year;
    },








      
    }

}
</script>

<style>

</style>